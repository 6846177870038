<template>
  <div class="view">
    <div class="content">
      <logo-banner/>
      <div class="wrapper">
        <button class="btn-green scanButton btn" @click="$router.push({name: 'Scan', params: {type:'checkIn'}})">
          <h5>
            <b-icon-camera-fill font-scale="0.9999"/>
            <span class="ml-2">Scannen</span>
          </h5>
        </button>
        <h5 v-if="!getCheckIns.length" class="mt-3">
          Sie haben sich noch nirgendwo eingecheckt.
        </h5>
        <b-row class="mt-3" align-h="center" v-for="checkIn in getCheckIns.slice().reverse()" :key="checkIn.id">
          <b-col lg="10" class="grey-bg card">
            <h4>{{ checkIn.name }}</h4>
            <div>
              {{ (new Date(Date.parse(checkIn.date))).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'}) }}
              {{ checkIn.endDate ? ' - ' + (new Date(Date.parse(checkIn.endDate))).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) : '' }}
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <menu-bar active-button="checkins"></menu-bar>
  </div>
</template>

<script>
import MenuBar from "../components/MenuBar";
import LogoBanner from "../components/LogoBanner";
import {mapGetters} from "vuex";

export default {
  name: "CheckIns",
  components: {
    LogoBanner,
    MenuBar,
  },
  computed: {
    ...mapGetters([
      'getCheckIns'
    ]),
  },
}
</script>

<style scoped>
h5 {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scanButton {
  margin-top: 16px;
}
</style>
